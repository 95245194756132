import type Band from '~/types/band'
import type MediaLink from '~/types/mediaLinks'

const TARGET_SECTION_NAMES = [
  'biography',
  'country',
  'genres',
  'has_profile_picture',
  'media_links',
  'name',
  'news',
] as const

export function useArtistProfileForce(importedBand: MaybeRefOrGetter<Band>) {
  const { t } = useI18n()

  function getValueInBandFromSectionName(
    target: (typeof TARGET_SECTION_NAMES)[number],
  ) {
    const band = toValue(importedBand)

    switch (target) {
      case 'biography':
        return band.biography
      case 'country':
        return band.tags.identity.country
      case 'genres':
        return band.tags.identity.subgenre
      case 'has_profile_picture':
        return band.has_profile_picture
      case 'media_links':
        return band.media_links
      case 'name':
        return band.name
      case 'news':
        return band.news
    }
  }

  function valueIsFilled(
    value: ReturnType<typeof getValueInBandFromSectionName>,
  ): boolean {
    if (value === null) return false
    if (typeof value === 'string' || value instanceof Array)
      return value.length > 0
    return !!value
  }

  function removePointsIfFalse(
    value: ReturnType<typeof getValueInBandFromSectionName>,
    pointsToRemove: number,
  ): number {
    return valueIsFilled(value) ? 0 : pointsToRemove
  }

  /**
   * Missing media links can remove up to 20 points !
   */
  const mediaLinksForceToRemove = computed<number>(() => {
    const band = toValue(importedBand)

    if (!band.media_links) return 0
    const hasVanillaLink = band.link.length > 0
    const numberOfFilledMediaLinks =
      Object.values(band.media_links).reduce<number>(
        (accumulator, value) =>
          isDefined(value) && typeof value !== 'number' && value.length > 0
            ? accumulator + 1
            : accumulator,
        0,
      ) + (hasVanillaLink ? 1 : 0)

    return numberOfFilledMediaLinks === 0
      ? 20
      : numberOfFilledMediaLinks < 4
        ? 10
        : 0
  })

  const bandForce = computed<number>(() => {
    // Will remove N points on each failure
    // Can't go over 100 !
    return (
      100 -
      // Band has a name
      (removePointsIfFalse(getValueInBandFromSectionName('name'), 10) + // 10
        // Band has a country
        removePointsIfFalse(getValueInBandFromSectionName('country'), 10) + // 20
        // Band has at least one subgenre selected
        removePointsIfFalse(getValueInBandFromSectionName('genres'), 20) + // 40
        // Band has a profile picture
        removePointsIfFalse(
          getValueInBandFromSectionName('has_profile_picture'),
          20,
        ) + // 60
        // Band wrote a biography
        removePointsIfFalse(getValueInBandFromSectionName('biography'), 10) + // 70
        // Band wrote some news
        removePointsIfFalse(getValueInBandFromSectionName('news'), 10) + // 80
        mediaLinksForceToRemove.value) // 100
    )
  })

  const profileNudgeMessage = computed(() => {
    const missingTarget = TARGET_SECTION_NAMES.find((section) => {
      if (section !== 'media_links')
        return !valueIsFilled(getValueInBandFromSectionName(section))
      return !!mediaLinksForceToRemove.value
    })

    if (!missingTarget) return undefined

    return t(`shared.profile.force.band.${missingTarget}`)
  })

  return { bandForce, profileNudgeMessage } as const
}
